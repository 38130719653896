import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import { config } from "../config/config";
import {
  handleBodyClasses,
  dumpLayoutToStorage,
  layoutReducer,
  layoutInit
} from "../common/layout";

import { userReducer, requestUserInfo } from "../common/user";
import { navigationReducer } from "../common/navigation";
import { chatReducer, chatInit } from "../common/chat";
import { eventsReducer } from "../common/calendar";
import { todoReducer } from "../common/todo";
import { i18nReducer, i18nInit } from "../common/i18n";
//import { outlookReducer } from "../views/outlook";
import { orderReducer } from "../common/order";
import { addressReducer } from '../common/address';
import { olxstoreReducer } from '../common/olxstore';
import { userAppReducer } from '../common/userApp';
import { payMethodReducer } from '../common/paymethod/payMethodReducer';

/*import {
  voiceReducer,
  VoiceMiddleware,
  voiceControlOn
} from "../common/voice-control";
*/

//  voice: voiceReducer,
//  outlook: outlookReducer,
export const rootReducer = combineReducers({
  layout: layoutReducer,
  navigation: navigationReducer,  
  user: userReducer,
  userapp: userAppReducer,
  chat: chatReducer,
  events: eventsReducer,
  todo: todoReducer,
  i18n: i18nReducer,
  order: orderReducer,
  address: addressReducer,
  olxstore: olxstoreReducer,
  paymethod: payMethodReducer,
});

const persistConfig = process.env.NODE_ENV === "development" ?
    {
        key: 'root',
        storage,
        blacklist: ['layout'], // allow auth token, config to save to storage in dev mode. 
    } :
    {
        key: 'root',
        storage,
        blacklist: ['layout', 'userapp'],
    };



const persistedReducer = persistReducer(persistConfig, rootReducer);
//const persistedReducer = rootReducer;


//       VoiceMiddleware
const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk,
      handleBodyClasses,
      dumpLayoutToStorage,
    )
  )
);


store.dispatch(layoutInit());
store.dispatch(chatInit());
store.dispatch(requestUserInfo());
store.dispatch(i18nInit());

/*if (config.voice_command_auto) {
  store.dispatch(voiceControlOn());
}*/

///*
let persistor = persistStore(store);

export {
  store, persistor
}
//*/

export default store;





