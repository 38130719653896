import React from 'react';
import ToggleMenu from "./ToggleMenu";

export default function EmbeddedHeader(props) {

    {/* <div className="pull-right">
            xxxxx<i className="fa fa-reorder fa-2x mobile-detected" />
        </div> */}


    return (
        <div>
        < ToggleMenu className="btn-header pull-right" /* collapse menu button */ />
        </div>
    );

}