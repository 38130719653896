import React from 'react';

export default class LoadingMessage extends React.Component { 

  
  render() {
    const message = this.props.message ? this.props.message : "Processing";
    const cssClass = this.props.cssClass ? this.props.cssClass : "font-md";
    return (
      <span className={cssClass} >
        <i className="fa fa-spinner fa-spin" /> {message}...
      </span>
    );}
}
