import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

export const Login = Loadable({
    loader: () => import("./components/Login"),
    loading: Loading
});

const LockedScreen = Loadable({
    loader: () => import("./components/LockedScreen"),
    loading: Loading
});

export const Register = Loadable({
    loader: () => import("./components/Register"),
    loading: Loading
});

const Forgot = Loadable({
    loader: () => import("./components/Forgot"),
    loading: Loading
});

export const Reset = Loadable({
    loader: () => import("./components/Reset"),
    loading: Loading
});

export const AccountLookup = Loadable({
    loader: () => import("./components/AccountLookup"),
    loading: Loading
});

export const SSO = Loadable({
    loader: () => import("./components/SSO"),
    loading: Loading
})

export const routes = [
    {
        path: "/login",
        exact: true,
        component: Login,
        name: "Login"
    },
    {
        path: "/lock",
        exact: true,
        component: LockedScreen,
        name: "Locked Screen"
    },
    {
        path: "/register",
        exact: true,
        component: Register,
        name: "Register"
    },
    {
        path: "/forgot",
        exact: true,
        component: Forgot,
        name: "Forgot"
    },
    {
        path: "/AccountLookup",
        exact: true,
        component: AccountLookup,
        name: "Account Lookup"
    },
    {
        path: "/SSO",
        exact: true,
        component: SSO,
        name: "SSO"
    }
];
