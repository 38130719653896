import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./store/configureStore";
import { authRoutes } from "./routes";
import { Layout } from "./common/layout";
import { Login, Register, Reset } from './views/auth';
import { NoService } from './views/noservice';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import { ToastProvider } from 'react-toast-notifications';
import * as stact from './common/olxstore/olxstoreActions';
import * as UA from './common/userApp/userAppActions';
import $ from "jquery";


class App extends Component {
      
    state = {
        loggedIn: false,    // does not actually control login
        siteConfig: false,
        siteAdmin: false,
        nosvcmsg: "Loading...",
        pwResetMode: false,
        site: "",
        storeID: ""
    };


  /*-----------------------------------------------------------------------
   * componentDidMount
   -----------------------------------------------------------------------*/
  componentDidMount() {
    console.log("App did mount!");

    /* There must be at least 2 bits of information in the URL
     * 1- The store ID. Site needs to get its configuration from a store, store name, etc. 
     * 2- Site (stub). Informs customer portal how to reach the API to get all other information it needs. 
     * The rest of the information is related to a customer who has to have some prior association with a store which
     * will be reflected in their user name. 
     * */

    var url_string = window.location.href;
    var url = new URL(url_string);
    var site = url.searchParams.get("site");   
    var storeID = url.searchParams.get("store"); 
    var regkey = url.searchParams.get("invkey"); // registration key. Must match server key to allow registration

      // these two fields are a bit redundant... but the prior is our internal record of the password reset request
      // and the latter is the microsoft identity generated token needed for the actual reset. 
      var resetid = url.searchParams.get("resetid"); // password reset key. Must match server key to allow reset. 
      var pwresettoken = url.searchParams.get("token");
      //if (pwresettoken !== null) this.setCookie(UA.SET_RESET_TOKEN, pwresettoken); // needed when user attempts to reset

      if (site === null || storeID === null) {
          // we don't have the minimum requirements for this application to contact an API 
          // and initialize. 
          this.setState({
              ...this.state,
              nosvcmsg: "Site not configured. Invalid link.",
              site: "",
              storeID: ""
          });
          return;
      }
    
      // domain where subdomain sites would be located. Ending must be our domain
      // Fixme... this will be changed to https & orderlogix for production. 

      var apibase = ""; 

      if (process.env.NODE_ENV === "development")
      {
        // dev mode. 
          apibase = "http://" + site + ".OLX.com/olxcart.webapi";
      }

      else {
          /* production mode. key indicates production or training. For example "thane" or "thane_train". Truncate the _train" and 
           * adjust the URL accordingly */
          if (site.includes("train")) {
              site = site.replace("train", ""); // remove train hint
              apibase = "https://" + site + ".orderlogix.com/training/olxcart.webapi";
          }
          else apibase = "https://" + site + ".orderlogix.com/production/olxcart.webapi";
      }

      console.log("apibase:" + apibase);
      //this.setCookie(UA.SET_API_BASE, apibase);

    // this is the store ID, we have to make a API call to this right away get store details, otherwise
      // we won't know what kind of non-customer specific details to display... store name, images, etc. 


     /* store configureation URL. To save a round trip we also pass the registration key if provided
     * so we can detect if the registration has already been converted into an account. We don't return
     * any information about the account, just an indicator that the registration is consumed so we don't
     * need to go to the registration page. It can also return a response that the registration is expired. 
     */
      console.log("Fetching config for store: "+storeID);
      let _url = apibase + "/api/account/config/" + storeID;
      if (regkey !== null) _url = apibase + "/api/account/config/" + storeID + "/regkey/" + regkey;
      if (resetid !== null) _url = apibase + "/api/account/config/" + storeID + "/reset/" + resetid;


    // We need to get the store details right quick... otherwise the user wont be able to do much
    // At least for now, this is anonymous. 
    $.ajax({
      url: _url,
      type: "GET",
      context: this,
        success: function (resp) {

            if (resp.length === 0) {
                // clear these because they are used by render
                this.setState({
                    nosvcmsg: "Invalid store ID.",
                    site: "",
                    storeID: ""
                }); 
                return;
            }

            // normal mode... clear regkey that might be cached memory. 
            if (resp[0].startupMode === 0) {
                this.setCookie(UA.SET_REGKEY, "");
            }


          if (resp[0].startupMode === 1) {
              // server confirmed that registration key is valid. Set to session storage  so that
              // registration page will be displayed. 
              this.setCookie(UA.SET_REGKEY, regkey);
          }

          if (resp[0].startupMode === 2) {
              // Registration key no longer valid... let user try to relocate/refresh invitation`
              this.setCookie(UA.SET_REGKEY, "expired");
          }

            // Save API base and reset token (if applicable)
            this.setCookie(UA.SET_API_BASE, apibase);
            if (pwresettoken !== null) this.setCookie(UA.SET_RESET_TOKEN, pwresettoken); // needed when user attempts to reset


          if (resp[0].startupMode === 3) {
            // valid password reset request. Enable reset page route. 
              this.setState({
                  pwResetMode: true,
              }); 
          }

          // store config obtained.... goood. This is trigger rerender when we have site details
          this.setState({
              ...this.state,
              siteConfig: true,
              site: site,
              storeID: storeID
          });

          // Deserialize some objects that we encoded. 
          resp[0].paymentConfig = JSON.parse(resp[0].paymentConfig)
          store.dispatch({ type: "SMART_SKIN", payload: { name: resp[0].themeName } });
          store.dispatch({ type: stact.STORE_ALL_SET, val: resp[0] });
           
      },

      error: function (err) {

        // could not obtain store config. Just allow pass-through default site not configured message
        //alert("store config failed. Is store ID correct?");
        console.log("Incorrect store ID provided.");

        // clear these because they are used by render
        this.setState({
            nosvcmsg: "Site configuration error. Please contact administrator",
            site: "",
            storeID: ""
        }); 
      }
    });
    
  }


    
    setCookie(name, value) {
        // No this isn't actually using cookies because browser too restrictive in incognito mode,
        // and we don't want this data sent back to the server anyway. 
        store.dispatch({ type: name, val: value });
      
    }

    killCookie(name) {

        if (name === "accessToken") store.dispatch({ type: UA.SET_ACCESS_TOKEN, val: "" });
        
        // trigger refresh
        this.setState({
            loggedIn: false
        });
    }

  /*-----------------------------------------------------------------------
    * onLoggedIn 
    * This function provides a way for the login screen to signal that
    * login has been successful (so routes to app screens are not intercepted.)
    -----------------------------------------------------------------------*/
    onLoggedIn = (isAdmin, firstName, accessToken) => {

        console.log("App.onLoggedIn called.");

        // store it so the rest of the app can use it. 
        this.setCookie(UA.SET_FIRST_NAME, firstName);
        this.setCookie(UA.SET_ACCESS_TOKEN, accessToken);

        // Now that were logged in, lets get some common information that may be
        // needed by multiple screens... for example Order Data

        // get the order details async
        this.refreshOrders();

        // get the payment details async
        this.refreshPaymethods(); 

        // get the address details async
        this.refreshAddresses();

        // re-render.
        this.setState({
            loggedIn: true,
            siteAdmin: isAdmin,
        });

        this.applyThemeCustomization();
    }

    applyThemeCustomization() {
        //console.log(store.getState().olxstore.storeOpts.theme);
        const backgroundColor = store.getState().olxstore.storeOpts.theme.backgroundColor;
        const menuColor = store.getState().olxstore.storeOpts.theme.menuColor;
        const menuTextColor = store.getState().olxstore.storeOpts.theme.menuTextColor;// + '!important'; 
        const menuForegroundColor = store.getState().olxstore.storeOpts.theme.menuForegroundColor;

        var r = document.querySelector(':root');
        r.style.setProperty('--theme-primaryhover-color', menuForegroundColor);
        r.style.setProperty('--theme-aside-color', menuColor);
        r.style.setProperty('--theme-menu-color', menuColor);
        r.style.setProperty('--theme-menutext-color', menuTextColor);
        r.style.setProperty('--theme-background-color', backgroundColor);
        r.style.setProperty('--theme-footer-color', store.getState().olxstore.storeOpts.theme.footerColor);
        r.style.setProperty('--theme-footertext-color', store.getState().olxstore.storeOpts.theme.footerTextColor);

        if (store.getState().olxstore.storeOpts.theme.menuColor === store.getState().olxstore.storeOpts.themeDefaults.menuColor) {
            r.style.setProperty('--theme-menu-color', 'none');
        }
    }

    /*-----------------------------------------------------------------------
    * onLoggedOut
    * Clear access token, refresh interface.
    -----------------------------------------------------------------------*/
    onLoggedOut() {
        this.killCookie("accessToken");
    }


    /*-----------------------------------------------------------------------
    * refreshOrders
    * This is located here because it is needed from multiple screens. 
    -----------------------------------------------------------------------*/
    refreshOrders() {
        console.log("App.refreshOrders called.");

        var sess = store.getState().userapp;
        var apibase = sess.apiBase;
        //console.log("apiBase:" + apibase);
        var tkn = sess.accessToken;

        // get auth bits here
        var hdrs = { 'Content-Type': 'application/x-www-form-urlencoded' };
        hdrs.Authorization = 'Bearer ' + tkn;


        // get order details
        $.ajax({
            url: apibase + "/api/account/orders",
            type: "GET",
            context: this,
            headers: hdrs,
            success: function (resp) {

            //take care of global order manipulations here
              var ts = store.getState();
              var orderPrefixesToStrip = ts.olxstore.storeOpts.orderPrefixesToStrip;
             
              if (orderPrefixesToStrip !== null) {
                var prefixes = orderPrefixesToStrip.split(",");
                resp.data.forEach((ord) => {
                  prefixes.forEach((pfx) => {
                    if (ord.value.webOrderNumber.startsWith(pfx)) {
                      ord.value.webOrderNumber = ord.value.webOrderNumber.replace(pfx, "");
                    }
                  });
                });
              }
              //get the shipment details assigned into the items for easier display later
              resp.data.forEach((ord) => {
                ord.value.shipments.forEach((shipment) => {
                  shipment.items.forEach((itm) => { 
                    //get related items and add the details
                    let shippedItems = ord.value.items.filter(function (i) {
                      return i.id === itm;
                    });

                    //really only one returned with each iteration
                    shippedItems[0].trackingNumber = shipment.trackingNumber;
                    shippedItems[0].shipMethod = shipment.shipMethod;
                    shippedItems[0].shipmentTransactionID = shipment.id;
                  });
                });
              });
          
              // I assume we have direct access to the store here
              store.dispatch({ type: 'ORDER_SET', val: resp.data });

            },
            error: function (err) {
                //$("#msg").text("Authentication failed");
                alert("Order Data Access failed. Are you logged in?");
            }
        });

    }
    /*-----------------------------------------------------------------------
    * refreshPaymentMethods
    * This is located here because it is needed from multiple screens. 
    -----------------------------------------------------------------------*/
    refreshPaymethods() {

        var sess = store.getState().userapp;
        var apibase = sess.apiBase;
        var tkn = sess.accessToken;

        // get auth bits here
        var hdrs = { 'Content-Type': 'application/x-www-form-urlencoded' };
        hdrs.Authorization = 'Bearer ' + tkn;

        // get the payment details
        $.ajax({
            url: apibase + "/api/account/cards",
            type: "GET",
            context: this,
            headers: hdrs,
            success: function (resp) {
                store.dispatch({ type: 'PAYMENT_SET', val: resp.data });
            },
            error: function (err) {
            //$("#msg").text("Authentication failed");
              alert("Data Access failed. Are you logged in?");
            }
        });
    }



    /*-----------------------------------------------------------------------
    * refreshAddresses
    * This is located here because it is needed from multiple screens. 
    -----------------------------------------------------------------------*/
    refreshAddresses() {
        // get auth bits here
        var sess = store.getState().userapp;

        var apibase = sess.apiBase;
        var tkn = sess.accessToken;

        var hdrs = { 'Content-Type': 'application/x-www-form-urlencoded' };
        hdrs.Authorization = 'Bearer ' + tkn;

        $.ajax({
            url: apibase + "/api/account/addresses",
            type: "GET",
            context: this,
            headers: hdrs,
            success: function (resp) {
                //console.log("got addresses");
                //this.props.onAddressSet(resp.data);
                store.dispatch({ type: 'ADDRESS_SET', val: resp.data });
            },

            error: function (err) {
                //$("#msg").text("Authentication failed");
                alert("Address Data Access failed. Are you logged in?");
            }
        });
    }



    /*-----------------------------------------------------------------------
    * onRegistered
    * If user successfully registers, we can take them out of registration
    * mode    
    -----------------------------------------------------------------------*/
    onRegistered = () => {
        alert("registration complete");
    }

    /*-----------------------------------------------------------------------
     * onRefreshEntity
     * This function is passed into all the top-level view components. 
     * Provides a way for these components to request a refresh of this data
     * that may be shared by multiple views. 
     -----------------------------------------------------------------------*/
    onRefreshEntity(entname) {
        if (entname === "paymethod") this.refreshPaymethods(); 
        if (entname === "addresses") this.refreshAddresses(); 
        if (entname === "orders") this.refreshOrders();
  }

  /*-----------------------------------------------------------------------
   * onPasswordChanged
   * After successful password change, come out of reset mode and let the
   * user login normally. Clear PW change intercept route
   *---------------------------------------------------------------------*/
  onPasswordChanged() {
      this.setState({
        ...this.state,
        pwResetMode: false
    }); 
  }


  render() {

      var sess = store.getState().userapp;

      // not logged in intercept routes. We'll allow Login/Register/Lock pages only in this state.
      let nologinir = sess.accessToken ? null : <Route path="/" render={(props) => <Login onLoggedIn={this.onLoggedIn} />} />;

      // no service intercept route. A site stub and store id are required in order to know which API to connect with. 
      let nosvcir = null;
      if (this.state.site === "" || this.state.storeID === "") {
      nosvcir = <Route path="/" render={(props) => <NoService msg={this.state.nosvcmsg} />} />; // no service intercept route. If site details are not provided 
      }

    /* user is visiting site with a registration key. This key can be used 
    * to contact the server and get the registration invitation. The user 
    * will have to provide a matching email address to successfully register
    * and the customer ID will be obtained on the back-end (because it is
    * part of the invitation) */
      const regir = sess.regKey ? <Route path="/" render={(props) => <Register onLoggedIn={this.onLoggedIn.bind(this)} />} /> : null; 

      /* Password Reset link provided. User will be put into this mode after the reset link is validated by the server
        * during initial configuration call. */
      debugger;
      const resetir = this.state.pwResetMode ? <Route path="/" render={(props) => <Reset onPasswordChange={this.onPasswordChanged.bind(this)} />} /> : null; 

      var _main = (
          <IntlProvider locale="en">
              <ToastProvider>
                  <HashRouter>
                      <Switch>      

                          {nosvcir}
                          {resetir}

                          {authRoutes.map((route, idx) => {
                            return route.component ? (
                              <Route
                                  key={idx}
                                  path={route.path}
                                  exact={route.exact}
                                  name={route.name}
                                  render={props => <route.component {...props} onLoggedIn={this.onLoggedIn} />}
                                />
                              ) : null;
                          })}

                          {regir}
                          {nologinir}

                          {/*<Route path="/" name="Home" component={Layout} />*/}
                          <Route path="/" name="Home" render={props => <Layout onRefreshEntity={this.onRefreshEntity.bind(this)} onLoggedOut={this.onLoggedOut.bind(this)} />} />
                      </Switch>
                  </HashRouter>
              </ToastProvider>
          </IntlProvider>
      );

      _main = <Provider store={store}><PersistGate loading={null} persistor={persistor}>{_main}</PersistGate></Provider>;         
       // the following can maike the Persistance conditional... but it may not be necessary.       
      /*if (process.env.NODE_ENV === "development") {
          _main = <Provider store={store}><PersistGate loading={null} persistor={persistor}>{_main}</PersistGate></Provider>;         
          //_main = <Provider store={store}>{_main}</Provider>;
      }
      else {
          // don't use local storage persistance. 
          _main = <Provider store={store}>{_main}</Provider>;
      }*/

      return _main; 
    }

}

export default App;
