import React from 'react';
import $ from "jquery";
import LoadingMessage from "./LoadingMessage";
export default class DialogModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false // will be true when ajax request is running
    };

  }

  onSaveComplete(closeModal) {
    console.log("spinner turned off");
    this.setState({
      loading: false
    });

    if (closeModal) {
      if (this.props.saveCompleteMessage) {
        $("#" + this.props.modalName + "_saveCompleteModal").modal('show');
      }
      else {
        //succussful, hide popup
        $("#" + this.props.modalName).modal('hide');
      }

    }
  }

  onSave(Confirmed) {
    // when confirm message property set... first call to this method is confirmed=false
    if (this.props.saveConfirmMessage && !Confirmed) {
      $("#" + this.props.modalName + "_saveConfirmModal").modal('show');
    }
    else {
      console.log("spinner turned on");
      this.setState({
        loading: this.props.useSpinner
      });
      //call the save function
      this.props.onSave(this.onSaveComplete.bind(this));
      console.log("save done");
    }
  }

  doDelete(props) {

    $('#delModal').modal('hide');

    // call function to perform on delete
    this.props.onDelete();

  }
  render() {
    const { loading } = this.state;
    const invalid= this.props.invalid ? this.props.invalid : false;
    const modalName = this.props.modalName ? this.props.modalName : "myModal"; 
    const saveConfirmModalName = modalName + "_saveConfirmModal";
    const saveCompleteModalName = modalName + "_saveCompleteModal";
    const saveButtonLabel = this.props.saveButtonLabel ? this.props.saveButtonLabel : "Save";
    const cancelButtonText = this.props.cancelButtonText ? this.props.cancelButtonText : "Cancel";
    const saveBtn = this.props.onSave ?
      <button type="button" id="btnDialogModalSave" disabled={loading || invalid} className="btn btn-lg btn-primary btn-default" onClick={() => this.onSave(this.props.saveConfirmMessage ? false : true)}>{saveButtonLabel}</button>
      : null;

    /* <button type="button" className="btn" onClick={() => this.doDelete(this.props)}>Delete</button> */
    let deleteBtn = this.props.onDelete ? 
      <button type="button" className="btn btn-lg btn-danger" data-toggle="modal" data-target="#delModal">Delete</button>
      : null; 
    let saveConfirmModal = this.props.saveConfirmMessage ?
      (
        <div id={saveConfirmModalName} className="modal" role="dialog" >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
                <label>{this.props.saveConfirmMessage}</label>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-12"> 
                  {/* <button type="button" className="btn btn-primary btn-default btn-lg" onClick={() => { this.doDelete(this.props) }}>Ok</button>*/}
                    <span className="pull-right">
                      <button type="button" className="btn btn-primary btn-default btn-sm" onClick={() => { $("#"+ saveConfirmModalName).modal('hide'); this.onSave(true); }}>Yes</button>
                      &nbsp;&nbsp;
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => { $("#" + saveConfirmModalName).modal('hide'); }} >No</button>
                    </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )
      : null;

    let saveCompleteModal = this.props.saveCompleteMessage ?
      (
        <div id={saveCompleteModalName} className="modal" role="dialog" >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <label>{this.props.saveCompleteMessage}</label>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-12">
                    <span className="pull-right">
                      <button type="button" className="btn btn-sm btn-primary btn-sm" data-target="#{modalname}" data-dismiss="modal">Ok</button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      : null;


    return (
      <div key={modalName}>
        <div id={modalName} className="modal" role="dialog">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">&times;</button>
                <h4 className="modal-title">{this.props.header}</h4>
              </div>
              <div className="modal-body">
                {this.props.body}

                {/* Modal within a modal.... this is a confirm delete box*/}
                <div id="delModal" className="modal" role="dialog">
                  <div className="modal-dialog modal-md">
                    <div className="modal-content">
                      <div className="modal-header">
                        <label>Are you sure you want to delete?</label>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-sm-12">
                            <button type="button" className="btn btn-primary btn-default btn-lg" onClick={() => { this.doDelete(this.props) }}>Yes</button>
                            <button type="button" className="btn btn-lg" data-target="#delModal" data-dismiss="modal">No</button>
                          </div>                    
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* -------------- End confirm box ------------------*/}

                {saveConfirmModal}
                {saveCompleteModal}

              </div>
              <div className="modal-footer">
                <div className="pull-left">{deleteBtn}</div>
                <span style={{ "marginRight": "5px" }}>{loading ? <LoadingMessage message="Processing Request" cssClass="font-lg"/> : null}
                {saveBtn}</span>
                            <button type="button" className="btn btn-lg btn-default" data-dismiss="modal">{cancelButtonText}</button>
              </div>
            </div>    
          </div>  
        </div>
      </div>
    );
  }

}