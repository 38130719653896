import React from "react";

//import FullScreen from "./FullScreen";
import ToggleMenu from "./ToggleMenu";
// import SpeechButton from "../../voice-control/components/SpeechButton";
// import SearchMobile from "./SearchMobile";

// import { Activities } from "../../activities";
//import { LanguageSelector } from "../../i18n";
import { connect } from 'react-redux';
 

//import RecentProjects from "./RecentProjects";

class Header extends React.Component {

 

  render() {

      const store = this.props.store;


      // folowing code is must otherwise preview functionality will not work
      const headerColor = store.storeOpts.theme.headerColor;
      const headerTextColor = store.storeOpts.theme.headerTextColor;

      const navBackgroundColor = store.storeOpts.theme.navBackgroundColor;
      const navTextColor = store.storeOpts.theme.navTextColor;
      const navBoarderColor = store.storeOpts.theme.navBoarderColor;


      var r = document.querySelector(':root');

    
      r.style.setProperty('--theme-headertext-color', headerTextColor);
      if (store.storeOpts.theme.headerColor === store.storeOpts.themeDefaults.headerColor) {
          r.style.setProperty('--theme-header-color', headerColor);
          r.style.setProperty('--theme-header-image', '-webkit-gradient(linear,0 0,0 100%,from(#f3f3f3),to(#e2e2e2))');
          
      } else {
          r.style.setProperty('--theme-header-color', headerColor);
          r.style.setProperty('--theme-header-image', 'none');
          
      }

      r.style.setProperty('--theme-nav-color', navBackgroundColor);
      r.style.setProperty('--theme-navtext-color', navTextColor);
      r.style.setProperty('--theme-navboarder-color', navBoarderColor);



      //const navBarStyle = {
      //    backgroundImage: (store.storeOpts.theme.navBackgroundColor !== null ? 'none' : ''), backgroundColor: (store.storeOpts.theme.navBackgroundColor !== null ? store.storeOpts.theme.navBackgroundColor : ''),
      //    borderColor: (store.storeOpts.theme.navBoarderColor !== null ? store.storeOpts.theme.navBoarderColor : ''),
      //};

      var contactUsButton = ""
      if (store.storeOpts.contactButtonLocation === "Header") {
          if (store.storeOpts.contactButtonType === "Text") {
              contactUsButton = <div className="pull-right"><a href="#/contact" title="Contact Us" className="btn-header-link" > {store.storeOpts.contactButtonText} </a></div>
          }
          else if (store.storeOpts.contactButtonType !== "Hidden") {
              if (store.storeOpts.contactButtonType === "Phone Icon") {
                  contactUsButton = <div className="btn-header transparent pull-right"><span><a href="#/contact" title={store.storeOpts.contactButtonText} ><i className="fa fa-phone" /></a></span></div>
              }
              if (store.storeOpts.contactButtonType === "Email Icon") {
                  contactUsButton = <div className="btn-header transparent pull-right"><span><a href="#/contact" title={store.storeOpts.contactButtonText} ><i className="fa fa-envelope-o" /></a></span></div>
              }
              if (store.storeOpts.contactButtonType === "Web Icon") {
                  contactUsButton = <div className="btn-header transparent pull-right"><span><a href="#/contact" title={store.storeOpts.contactButtonText} ><i className="fa fa-globe" /></a></span></div>
              }
          }
           
      }

     
      return (
          
          <div className="extr-page">

              <header id="header" className="animated fadeInDown2" >
                  <div id="logo-group" >
          {/* 
           * Logo images normally comes from config.js, but we want this loaded from store settings
           * usual skin settings. Renamed span id since this seems to be what is being used to force the
           * skins defined logo on this element. 
           * 
           * <span id="logo">   
           * */}
         
                    <img id="logo"
              src={store.storeOpts.logoUrl}
                alt="Customer Portal"
            />

            
           
                    <span>
                        <h1 className="hidden-xs" style={{"marginTop": "15px" }}>{store.storeOpts.storeName}</h1>
          </span>
        </div>
       
        
        <div className="pull-right" /*pulled right: nav area*/>

                      <ToggleMenu className="btn-header pull-right" /* collapse menu button */   />

          {/* #MOBILE */}
          {/*  Top menu profile link : this shows only when top menu is active */}
          <ul id="mobile-profile-img" className="header-dropdown-list hidden-xs padding-5">
            <li className="">
              <a href="#/" className="dropdown-toggle no-margin userdropdown" data-toggle="dropdown">
                <img
                  src="assets/img/avatars/sunny.png"
                  alt="John Doe"
                  className="online"
                />
              </a>
              <ul className="dropdown-menu pull-right">
                <li>
                  <a href="#/" className="padding-10 padding-top-0 padding-bottom-0">
                    <i className="fa fa-cog" /> Setting
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a
                    href="#/views/profile"
                    className="padding-10 padding-top-0 padding-bottom-0"
                  >
                    <i className="fa fa-user" />
                    <u>P</u>rofile
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                    data-action="toggleShortcut"
                  >
                    <i className="fa fa-arrow-down" /> <u>S</u>hortcut
                  </a>
                </li>

                <li className="divider" />
                {/*
                <li>
                  <a href="#/"
                    className="padding-10 padding-top-0 padding-bottom-0"
                    data-action="launchFullscreen"
                  >
                    <i className="fa fa-arrows-alt" /> Full
                    <u>S</u>creen
                  </a>
                </li>
                */}
                <li className="divider" />
                <li>
                    <a href="#" onClick={this.props.onLoggedOut} className="padding-10 padding-top-5 padding-bottom-5" data-action="userLogout">
                    <i className="fa fa-sign-out fa-lg" />
                    <strong>
                      <u>L</u>ogout
                    </strong>
                  </a>
                </li>
              </ul>
            </li>
          </ul>


          
          {/* logout button */}
          <div id="logout" className="btn-header transparent pull-right"  >
            <span>
                              <a href="#" onClick={this.props.onLoggedOut} title="Sign Out" data-logout-msg="You can improve your security further after logging out by closing this opened browser"> 
                <i className="fa fa-sign-out" />
              </a>
            </span>
          </div>

          {/* search mobile button (this is hidden till mobile view port) */}
          {/* <SearchMobile className="btn-header transparent pull-right" /> */} 

          {/* input: search field */}
          {/*<form
            action="#/misc/search.html"
            className="header-search pull-right"
          >
            <input
              id="search-fld"
              type="text"
              name="param"
              placeholder="Find reports and more"
            />
            <button type="submit">
              <i className="fa fa-search" />
            </button>
            <a href="$" id="cancel-search-js" title="Cancel Search">
              <i className="fa fa-times" />
            </a>
          </form>*/}


            {/* <SpeechButton className="btn-header transparent pull-right hidden-sm hidden-xs" /> */}

          {/*<FullScreen className="btn-header transparent pull-right" />*/}
                {contactUsButton}
               

          {/* multiple lang dropdown : find all flags in the flags page */}
          {/*<LanguageSelector /> */}

        </div>
        {/* end pulled right: nav area */}
      </header>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state.olxstore
  };
};
export default connect(mapStateToProps)(Header);

