
import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const PayMethod = Loadable({
  loader: () => import("./components/Paymethod"),
  loading: Loading
});

export const routes = [
  {
    path: "/paymethod",
    exact: true,
    component: PayMethod,
    name: "Payment Methods"
  }
];

