import React from "react";
import { connect } from 'react-redux';
import PrivacyPolicy from "../../../common/layout/components/PrivacyPolicy";
import $ from "jquery";
// import { Dropdown, MenuItem } from "react-bootstrap";

class Footer extends React.Component {
     
    showPrivacyPolicyModal(e) {
        e.preventDefault();
        $("#privacyPolicyModal").modal();
    }

    render() {

        var r = document.querySelector(':root');
        r.style.setProperty('--theme-footer-color', this.props.store.storeOpts.theme.footerColor);
        r.style.setProperty('--theme-footertext-color', this.props.store.storeOpts.theme.footerTextColor);

        const store = this.props.store;
        var contactUsButton = ""
        if (store.storeOpts.contactButtonLocation === "Footer") {
            if (store.storeOpts.contactButtonType === "Text") {
                contactUsButton = <div className="pull-left"><span><a href="#/contact" title="Contact Us" > {store.storeOpts.contactButtonText} </a></span></div>
            }
            else if (store.storeOpts.contactButtonType !== "Hidden") {
                if (store.storeOpts.contactButtonType === "Phone Icon") {
                    contactUsButton = <div className="btn-header transparent pull-left"><span><a href="#/contact" style={{ marginTop: "0" }} title={store.storeOpts.contactButtonText} ><i className="fa fa-phone" /></a></span></div>
                }
                if (store.storeOpts.contactButtonType === "Email Icon") {
                    contactUsButton = <div className="btn-header transparent pull-left"><span><a href="#/contact" style={{ marginTop: "0"}} title={store.storeOpts.contactButtonText} ><i className="fa fa-envelope-o" /></a></span></div>
                }
                if (store.storeOpts.contactButtonType === "Web Icon") {
                    contactUsButton = <div className="btn-header transparent pull-left"><span><a href="#/contact" style={{ marginTop: "0"}} title={store.storeOpts.contactButtonText} ><i className="fa fa-globe" /></a></span></div>
                }
            }
        }

        var privacyPolicyLink = ""
        if (store.storeOpts.privacyPolicyText !== "" && store.storeOpts.privacyPolicyText!==null)
        {
            privacyPolicyLink = <div className="pull-right"><span><a href="#" title="Privacy Policy"  onClick={this.showPrivacyPolicyModal.bind(this)} >Privacy Policy</a></span></div>
        }

    //const inline = { display : "inline" };

    /*
    let phoneLink = storeOpts.csPhone === "" ? null :
      <div><a href={"tel:" + storeOpts.csPhone}><i className="fa fa-phone fa-2x" />{storeOpts.csPhone}</a> </div>;

    let emailLink = storeOpts.csEmail === "" ? null : 
      <div><a href={"mailto:" + storeOpts.csEmail}><i className="fa fa-envelope-square fa-2x" />{storeOpts.csEmail}</a> </div>;

    let facebookLink = storeOpts.facebookLink === null ? null :
      <div style={inline}><a href={storeOpts.facebookLink} target="new"><i className="fa fa-facebook-square fa-2x" /></a> </div>;

    let twitterLink = storeOpts.twitterLink === null ? null :
      <div style={inline}><a href={storeOpts.twitterLink} target="new"><i className="fa fa-twitter-square fa-2x" /></a> </div>;
      */
  
        return (
            <div className="page-footer" >

        {/*<div className="row">
          <div className="col-xs-3 col-sm-3">
            <span className="txt-color-white">
              {emailLink}
            </span>
          </div>

          <div className="col-xs-3 col-sm-3 text-right">
            <div className="txt-color-white inline-block">
              {phoneLink}
            </div>
          </div>

          <div className="col-xs-3 col-sm-3 text-right pull-right">
            <div>
              {facebookLink}&nbsp;
              {twitterLink}
            </div>
          </div>

        </div>*/}

            <div className="row">
               
                <div className="col-xs-3 col-sm-3 text-left">
                   
                    {contactUsButton}

                    
                     
                </div>
                <div className="col-xs-9 col-sm-9 text-left pull-right">
                    {privacyPolicyLink}
                    <PrivacyPolicy privacyPolicyText={store.storeOpts.privacyPolicyText} />
                </div>
            </div>

      </div>


    );
  }
}


const mapStateToProps = (state) => {
    return {
        store: state.olxstore
    };
};

export default connect(mapStateToProps)(Footer);
