import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

export const Admin = Loadable({
  loader: () => import("./components/Admin"),
  loading: Loading
});

const Support = Loadable({
  loader: () => import("./components/Support"),
  loading: Loading
});

export const routes = [
  {
    path: "/admin",
    exact: true,
    component: Admin,
    name: "Admin"
  },
  {
    path: "/support",
    exact: true,
    component: Support,
    name: "Support"
  }
];



