
import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Addresses = Loadable({
  loader: () => import("./components/Addresses"),
  loading: Loading
});


export const routes = [
    {
        path: "/addresses",
        exact: true,
        component: Addresses,
        name: "Addresses"
    }
];

