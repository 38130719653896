import React from "react";
// import ToggleShortcut from "./ToggleShortcut";

import { connect } from "react-redux";

class LoginInfo extends React.Component {
  componentWillMount() {}

  render() {
    return (
      <div >
        <span>
          {/* <ToggleShortcut> */}
          {/*<img src={this.props.picture} alt="me" className="online" />*/}
            <h1>{this.props.username}</h1>
          {/* </ToggleShortcut> */}
        </span>
      </div>
    );
  }
}

const mapStateToProps = state => state.userapp;

export default connect(mapStateToProps)(LoginInfo);
