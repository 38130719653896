
export function orderReducer(
  state = {
    orderData: {}
  },
  action)

{  
  switch (action.type) {

    case 'ORDER_SET':
      // 
      return {
        ...state,
        orderData: action.val
      };

    default:
      return state;
  }
}
