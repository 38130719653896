import * as UA from './userAppActions';

/* userAppReducer
 * Added this analogue to the smartadmin user reducer, because for reasons that I could not figure out right now,
 * The userReducer is not persisted redux-persist with page reloads
*/

export function userAppReducer(
  state = {
        username: null,
        picture: "assets/img/avatars/sunny.png",
        activity: null,
        isAdmin: null,
        isSupport: null,
        accessToken: "",
        apiBase: "",
        regKey: "",
        pwResetToken: "",
        firstName: ""
    },action)
{
    switch (action.type) {

        case 'USER_CHANGE':
            return {
                ...state,
                username: action.val.firstName,
                isAdmin: action.val.isAdmin,
                isSupport: action.val.isSupport,
                picture: "assets/img/avatars/sunny.png"
            };
        case UA.SET_ACCESS_TOKEN:
            //alert("access token:" + action.val);
            return {
                ...state,
                accessToken: action.val
            };
        case UA.SET_API_BASE:
            console.log("set apibase:" + action.val);
            return {
                ...state,
                apiBase: action.val,
            };
        case UA.SET_USER_NAME:
            //alert("user:" + action.val);
            return {
                ...state,
                username: action.val
            };
        case UA.SET_REGKEY:
            return {
                ...state,
                regKey: action.val
            };
        case UA.SET_RESET_TOKEN:
            return {
                ...state,
                pwResetToken: action.val
            };
        case UA.SET_FIRST_NAME:
            return {
                ...state,
                firstName: action.val
            };
    default:
      return state;
  }
}
