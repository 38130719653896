import * as stact from './olxstoreActions';

export function olxstoreReducer(
    state = {
        options: null, 
        storeName: "",
        logoUrl: "",
        registerWelcomeTxt: "",
        TOSText: ""
    },
    action) {
    switch (action.type) {

        case stact.STORE_ALL_SET:

          /* there are many settings that come from the store service. Rather than edit this file every
           * time we have a new one.... put all the settings in storeOpts.
           */
            return {
                ...state,
                storeOpts: action.val,
            };

        case stact.STORE_NAME_SET:
            return {
                ...state,
                storeName: action.val.storeName,
            };
        case stact.LAYOUT_RESET:
            return {
                ...state,
                dummy: state.dummy + 1,
            }

        default:
            return state;

      }
}
