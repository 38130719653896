import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Orders = Loadable({
  loader: () => import("./components/Orders"),
  loading: Loading
});

const OrderDetail = Loadable({
    loader: () => import("./components/OrderDetail"),
    loading: Loading
});

export const routes = [
  {
        path: "/orders",
        exact: true,
        component: Orders,
        name: "Orders"
    },
    {
        path: "/orders/order/",
        exact: false,
        component: OrderDetail,
        name: "OrderDetail"
    }
];
