import { routes as appViews } from "./views/app-views";
import { routes as auth } from "./views/auth";
import { routes as calendar } from "./views/calendar";
import { routes as dashboards } from "./views/dashboard";
import { routes as eCommerce } from "./views/e-commerce";
import { routes as forms } from "./views/forms";
import { routes as graphs } from "./views/graphs";
import { routes as maps } from "./views/maps";
import { routes as misc } from "./views/misc";
//import { routes as outlook } from "./views/outlook";
import { routes as smartadminIntel } from "./views/smartadmin-intel";
import { routes as tables } from "./views/tables";
import { routes as ui } from "./views/ui";
import { routes as widgets } from "./views/widgets";
import { routes as welcome } from "./views/welcome";
import { routes as paymethod } from "./views/paymethod";  // Removed because i need to pass properties to this
import { routes as orders } from './views/orders';
import { routes as subscriptions } from './views/subs';
import { routes as noservice } from './views/noservice';
import { routes as addresses } from './views/address';
import { routes as contact } from './views/contact';
import { routes as admin } from './views/admin';

export const routes = [ 
  ...appViews,
  ...calendar,
  ...dashboards,
  ...eCommerce,
  ...forms,
  ...graphs,
  ...maps,
  ...misc,
  ...smartadminIntel,
  ...tables,
  ...ui,
  ...widgets,
  ...welcome,
  ...orders,
  ...addresses, 
  ...paymethod,
  ...subscriptions,
  ...noservice,
  ...contact,
  ...admin
];

// ...outlook,

//  ...addresses, 

export const authRoutes = [
  ...auth
];
