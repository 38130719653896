import React from "react";
//import { connect } from 'react-redux';
import DialogModal from "../../../common/ui/components/DialogModal";


class PrivacyPolicy extends React.Component  {
  
    render() {
        return (
            <DialogModal body={<div id="dialog-message">
                <div dangerouslySetInnerHTML={{ __html: this.props.privacyPolicyText }} className="privacypolicy-scrollable-area" />
            </div>}
                header={"Privacy Policy"}
                modalName="privacyPolicyModal"
                cancelButtonText={"Close"}
               
            />
        );
    }
}
 
export default PrivacyPolicy;
