import React from "react";
//import { OverlayTrigger, Tooltip } from "react-bootstrap";
//import ResetWidgets from "./ResetWidgets";
import SmallBreadcrumbs from "./SmallBreadcrumbs";


export default class Ribbon extends React.Component {
  render() {
    return (
      <div id="ribbon">
        <span className="ribbon-button-alignment">
          {/*<OverlayTrigger placement="bottom" overlay={tooltip}>
            <ResetWidgets />
          </OverlayTrigger>*/}
        </span>
        <SmallBreadcrumbs />
      </div>
    );
  }
}
