import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Subscriptions = Loadable({
  loader: () => import("./components/Subscriptions"),
  loading: Loading
});

const SingleSubscription = Loadable({
    loader: () => import("./components/SingleSubscription"),
    loading: Loading
});

export const routes = [
  {
        path: "/subscriptions",
        exact: true,
        component: Subscriptions,
        name: "Subscriptions"
    },
    {
        path: "/subscriptions/subscription",
        exact: true,
        component: SingleSubscription,
        name: "SingleSubscription"
    }
];
