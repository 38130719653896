import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

export const Welcome = Loadable({
    loader: () => import("./containers/Welcome"),
    loading: Loading
});


export const routes = [
  {
    path: "/",
    exact: true,
    name: "Welcome",
    component: Welcome
  },
  {
      path: "/welcome",
      exact: true,
      component: Welcome,
      name: "Welcome"
  }
];
