
export function addressReducer(
  state = {
    addressData: {}
  },
  action) {
  switch (action.type) {

    case 'ADDRESS_SET':
      // 
      return {
        ...state,
        addressData: action.val
      };

    default:
      return state;
  }
}
