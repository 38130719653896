import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

export const NoService = Loadable({
    loader: () => import("./components/NoService"),
    loading: Loading
});


export const routes = [
    {
        path: "/noservice",
        exact: true,
        component: NoService,
        name: "NoService"
    }
];
