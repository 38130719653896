import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Shortcut, Navigation } from "../../navigation";
//import Ribbon from "./Ribbon";
//import LayoutSwitcher from "./LayoutSwitcher";
import { Welcome } from '../../../views/welcome';
import EmbeddedHeader from './EmbeddedHeader';

import Header from "./Header";
import Footer from "./Footer";

import { routes } from "../../../routes";


class Layout extends React.Component {


    state = {
        embedded: false
    };

    componentDidMount() {
        var url_string = window.location.href;
        var url = new URL(url_string);
         
        var embedded = url.searchParams.get("embedded");   
        if (embedded !== null) {
            if (embedded.toUpperCase() === "TRUE") this.setState({ embedded: true });
        }
    }


    render() {
  
        var header = <Header onLoggedOut={this.props.onLoggedOut}  />;
        var footer = <Footer />;
        var eheader = null; 

        if (this.state.embedded) {
            header = null;
            footer = null; 
            eheader = <EmbeddedHeader />;
        }
      
        return (
            <div>

                {header}
                

                <Navigation storex={this.props.storex}  />

                <div id="main" role="main">

                    {eheader}

                  {/*<LayoutSwitcher />*/}
                  {/*<Ribbon />*/}
                    <Switch>
                        {routes.map((route, idx) => {
                          if (process.env.NODE_ENV === "production" && route.name === "Dashboard") {
                                // not exactly sure what is still directing to dashboard... but if it still happens
                                // hijack the route and go back to welcome. 
                              route = {
                                  path: "/dashboard",
                                  exact: false,
                                  component: Welcome,
                                  name: "Welcome"
                              }; 
                          }

                            return route.component ? (
                                <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => <route.component {...props} onRefreshEntity={this.props.onRefreshEntity} />}
                                />
                            ) : null;

                        })}

                        <Redirect from="/" to="/misc/404" />

                {/* <Redirect from="/" to="/dashboard/analytics" /> */}
                    </Switch>
                </div>

                {footer}
                <Shortcut />
            </div>
        );
    }
}


export default Layout;

