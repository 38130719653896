import React from "react";
import $ from "jquery";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { config } from "../../../config/config";
import store from "../../../store/configureStore";
import { navigationInit } from "../NavigationActions";
import NavMenuList from "./NavMenuList";
import { connect } from 'react-redux';


let navItems = require("../../../config/navigation.json").items.map(addId);

class NavMenu extends React.Component {
  componentWillMount() {
      
      // do nav menu customizations to nav config data first before it is dispatched.
      var subNavItem = navItems.find(f => f.title === "Subscriptions");
      if (subNavItem !== undefined) {
          subNavItem.title = this.props.store.storeOpts.subLabelPlural;
      }
      //navItems.find(f => f.title === "Subscriptions").title = this.props.store.storeOpts.subLabelPlural;

      // remove subscriptions
      if (this.props.store.storeOpts.subLabelPlural === "HIDE_ME") {
          navItems = navItems.filter(f => f.title !== "HIDE_ME");
      } 

      // remove stuff
      if (process.env.NODE_ENV === "production") {
        navItems = navItems.filter(f => f.title !== "Stuff");
      } 

      // Remove items when NOT embedded. 
      var url_string = window.location.href;
      var url = new URL(url_string);
      var embedded = url.searchParams.get("embedded");
      if (embedded === null) embedded = "";
      if (embedded.toUpperCase() !== "TRUE") {
          navItems = navItems.filter(f => f.title !== "Contact");
      } 

      store.dispatch(navigationInit(navItems));

    }


  componentDidMount() {
    const defaults = {
      accordion: true,
      speed: config.menu_speed,
      closedSign: "[+]",
      openedSign: "[-]"
    };
      
    //@todo get rid of jquery stuff

    // Extend our default options with those provided.
    const opts = $.extend({}, defaults, this.props);
    //Assign current element to variable, in this case is UL element
    const $this = $(findDOMNode(this));

    //add a mark [+] to a multilevel menu
    $this.find("li").each(function() {
      if ($(this).find("ul").length !== 0) {
        //add the multilevel sign next to the link
        $(this)
          .find("a:first")
          .append("<b class='collapse-sign'>" + opts.closedSign + "</b>");

        //avoid jumping to the top of the page when the href is an #
        if (
          $(this)
            .find("a:first")
            .attr("href") === "#"
        ) {
          $(this)
            .find("a:first")
            .click(function() {
              return false;
            });
        }
      }
    });

    //open active level
    $this.find("a.active").each(function(li) {
      $(this)
        .parents("ul")
        .slideDown(opts.speed);
      $(this)
        .parents("ul")
        .parent("li")
        .find("b:first")
        .html(opts.openedSign);
      $(this)
        .parents("ul")
        .parent("li")
        .addClass("open");
    });

    $this.find("li a").click(function() {
      if (
        $(this)
          .parent()
          .find("ul").length !== 0
      ) {
        if (opts.accordion) {
          //Do nothing when the list is open
          if (
            !$(this)
              .parent()
              .find("ul")
              .is(":visible")
          ) {
            const parents = $(this)
              .parent()
              .parents("ul");
            const visible = $this.find("ul:visible");
            visible.each(function(visibleIndex) {
              var close = true;
              parents.each(function(parentIndex) {
                if (parents[parentIndex] === visible[visibleIndex]) {
                  close = false;
                  return false;
                }
              });
              if (close) {
                if (
                  $(this)
                    .parent()
                    .find("ul") !== visible[visibleIndex]
                ) {
                  $(visible[visibleIndex]).slideUp(opts.speed, function() {
                    $(this)
                      .parent("li")
                      .find("b:first")
                      .html(opts.closedSign);
                    $(this)
                      .parent("li")
                      .removeClass("open");
                  });
                }
              }
            });
          }
        }
        if (
          $(this)
            .parent()
            .find("ul:first")
            .is(":visible") &&
          !$(this)
            .parent()
            .find("ul:first")
            .hasClass("active")
        ) {
          $(this)
            .parent()
            .find("ul:first")
            .slideUp(opts.speed, function() {
              $(this)
                .parent("li")
                .removeClass("open");
              $(this)
                .parent("li")
                .find("b:first")
                .delay(opts.speed)
                .html(opts.closedSign);
            });
        } else {
          $(this)
            .parent()
            .find("ul:first")
            .slideDown(opts.speed, function() {
              $(this)
                .parent("li")
                .addClass("open");
              $(this)
                .parent("li")
                .find("b:first")
                .delay(opts.speed)
                .html(opts.openedSign);
            });
        } 
        } 


      $this.find("li a").each(function () {
          $(this).parent().removeClass('active');
          $(this).parent().removeAttr('class');
      });

      $(this).parent().addClass('active');

    });

 

    }

    componentDidUpdate()
    {

        // folowing code is must otherwise preview functionality will not work
        const backgroundColor = this.props.store.storeOpts.theme.backgroundColor;
        const menuColor = this.props.store.storeOpts.theme.menuColor;
        const menuTextColor = this.props.store.storeOpts.theme.menuTextColor;// + '!important'; 
        const menuForegroundColor = this.props.store.storeOpts.theme.menuForegroundColor;
 
        

       
         
        var r = document.querySelector(':root');
        r.style.setProperty('--theme-primaryhover-color', menuForegroundColor);
        r.style.setProperty('--theme-aside-color', menuColor);
        r.style.setProperty('--theme-menu-color', menuColor);
        r.style.setProperty('--theme-menutext-color', menuTextColor);

        r.style.setProperty('--theme-background-color', backgroundColor);

        if (this.props.store.storeOpts.theme.menuColor === this.props.store.storeOpts.themeDefaults.menuColor)
        {
            r.style.setProperty('--theme-menu-color', 'none');
        }


        //// only when override is available
        //var isDefault = this.props.store.storeOpts.theme.menuColor === this.props.store.storeOpts.themeDefaults.menuColor;

        //$this.find("li").each(function (index) {
        //    if (!isDefault) {
        //        $(this).css({ "background-color": menuColor, "background-image": "none" });
        //    }
        //    else {
        //        $(this).removeAttr("style");
        //    }
        //});

        //// set color to aside with id="left-panel"
        //if (!isDefault) {
        //    $this.parent().parent().css({ "background-color": menuColor, "background-image": "none" });
        //}
        //else {
        //    $this.parent().parent().removeAttr("style");
        //}

        //$this.parent().parent().parent().find('#main').css({ "background-color": backgroundColor });
     
       
        //if (!isDefault) {
        //    $this.find("li a i").each(function (index) {
        //        $(this).css("cssText", "color:" + menuTextColor);
        //        $(this).parent().css("cssText", "color:" + menuTextColor);
        //    });
        //}
        //else {

        //    $this.find("li a i").each(function (index) {
        //        $(this).removeAttr("style");
        //        $(this).parent().removeAttr("style");
        //    });
        //}

    }


    render() {
 
        // folowing code is must otherwise preview functionality will not work
        const backgroundColor = this.props.store.storeOpts.theme.backgroundColor;
        const menuColor = this.props.store.storeOpts.theme.menuColor;
        const menuTextColor = this.props.store.storeOpts.theme.menuTextColor;// + '!important'; 
        const menuForegroundColor = this.props.store.storeOpts.theme.menuForegroundColor;





        var r = document.querySelector(':root');
        r.style.setProperty('--theme-primaryhover-color', menuForegroundColor);
        r.style.setProperty('--theme-aside-color', menuColor);
        r.style.setProperty('--theme-menu-color', menuColor);
        r.style.setProperty('--theme-menutext-color', menuTextColor);

        r.style.setProperty('--theme-background-color', backgroundColor);

        if (this.props.store.storeOpts.theme.menuColor === this.props.store.storeOpts.themeDefaults.menuColor) {
            r.style.setProperty('--theme-menu-color', 'none');
        }

        var filItems = navItems; 
        if (!this.props.user.isAdmin) {
            filItems = filItems.filter(f => f.title !== "Admin");
        }

        if (!this.props.user.isSupport) {
            filItems = filItems.filter(f => f.title !== "Support");
        }
      

        return navItems ? <NavMenuList items={filItems} /> : this.props.children;
  }
}

NavMenu.propTypes = {
  accordion: PropTypes.bool,
  speed: PropTypes.number,
  closedSign: PropTypes.string,
  openedSign: PropTypes.string
};

// emsure item have id
function addId(item) {
  if (item.items) {
    item.items = item.items.map(addId);
  }

  if (!item.id) {
    item.id = Math.random()
      .toString(36)
      .slice(2);
  }
  return item;
}

const mapStateToProps = (state) => {
  return {
    store: state.olxstore,
    user: state.userapp,
  };
};


export default connect(mapStateToProps)(NavMenu);